import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { GatsbyImage, getSrc } from 'gatsby-plugin-image'
import Markdown from 'react-markdown'
import { Button, Card, CardContent } from '@mui/material'
import { formatDistance } from 'date-fns'
import { useTranslation } from 'react-i18next'
import rehypeRaw from 'rehype-raw'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'

import Layout from '../components/layout'
import SEO from '../components/seo'
import getDateFnsLocale from '../utils/getDateFnsLocale'
import { Projects } from '../utils/navLinks'
import Link from '../components/link'

const Template = ({ data }) => {
    const { t, i18n } = useTranslation('projects')

    // this data prop will be injected by the GraphQL query below.
    const { edges } = data.allMarkdownRemark // data.allMarkdownRemark holds all your posts
    // eslint-disable-next-line react/prop-types
    const post = edges.find(edge => edge.node.frontmatter.key === i18n.language) // return the post by language
    const { frontmatter, html } = post.node

    const featuredImage = frontmatter.image.childImageSharp.gatsbyImageData

    return (
        <Layout>
            <SEO
                title={frontmatter.title}
                description={frontmatter.description}
                image={getSrc(featuredImage)}
                alreadyTranslated
            />
            <Card square elevation={0} className="projectPost">
                <GatsbyImage alt={frontmatter.title} image={featuredImage} />
                <CardContent className="postContent">
                    <Button
                        disableElevation
                        component={Link}
                        to={Projects.path}
                        variant="contained"
                        startIcon={<KeyboardArrowLeftIcon />}
                        classes={{ root: 'backButton', startIcon: 'backButtonIcon' }}
                    >
                        {t('title')}
                    </Button>
                    <h1 className="projectTitle">{frontmatter.title}</h1>
                    <span className="projectDate">
                        {formatDistance(new Date(frontmatter.date), new Date(), {
                            addSuffix: true,
                            locale: getDateFnsLocale(),
                        })}
                    </span>
                    <Markdown rehypePlugins={[rehypeRaw]} className="projectContent">
                        {html}
                    </Markdown>
                </CardContent>
            </Card>
        </Layout>
    )
}

export const pageQuery = graphql`
    query ($originalPath: String!) {
        allMarkdownRemark(filter: { frontmatter: { slug: { eq: $originalPath } } }) {
            edges {
                node {
                    html
                    frontmatter {
                        date(formatString: "MMMM DD, YYYY")
                        title
                        description
                        template
                        key
                        slug
                        image {
                            childImageSharp {
                                gatsbyImageData(
                                    height: 728
                                    width: 1200
                                    transformOptions: { cropFocus: NORTH }
                                )
                            }
                        }
                    }
                }
            }
        }
    }
`

Template.propTypes = {
    data: PropTypes.shape({
        allMarkdownRemark: PropTypes.shape({
            edges: PropTypes.arrayOf(
                PropTypes.shape({
                    node: PropTypes.shape({
                        frontmatter: PropTypes.shape({
                            title: PropTypes.string,
                            description: PropTypes.string,
                            date: PropTypes.string,
                        }),
                        html: PropTypes.string,
                    }),
                }),
            ),
        }),
    }),
}

Template.defaultProps = {
    data: {},
}

export default Template
